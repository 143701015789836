import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { Player } from 'video-react';

const ExperimentsWrapper = styled.main`
	padding: 0 20px 20px;
	position: relative;

	@media (min-width: 1024px) {
		height: 0;
		position: relative;
		padding: 155px 50px 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 200px;
		box-sizing: border-box;
	}
`;

const ExperimentListItem = styled.article`
	position: initial;
	width: 100%;
	margin-bottom: 20px;
	z-index: 1;
	flex-basis: 39%;

	video {
		width: 100%;
		max-width: 100%;
	}

	.video-react {
		padding-top: 0 !important;

		.video-react-big-play-button,
		.video-react-control-bar {
			display: none;
		}
	}

	@media (min-width: 1024px) {
		position: relative;
		flex-basis: 24.5%;
		margin: 0;
		top: ${props => `${props.styles.top}px`};
		left: ${props => `${props.styles.left}px`};

		&:nth-of-type(5),
		&:nth-of-type(6),
		&:nth-of-type(7),
		&:nth-of-type(8n + 12),
		&:nth-of-type(8n + 13),
		&:nth-of-type(8n + 14) {
			flex-basis: 33.3333%;
			padding-top: ${props => `${props.styles.top}px`};
		}

		&:nth-of-type(19),
		&:nth-of-type(20) {
			flex-basis: 35%;
			margin: 0 auto;
		}

		/* first row */
		&::first-of-type,
		&:nth-of-type(2),
		&:nth-of-type(3),
		&:nth-of-type(4) {
			top: ${props => `${props.styles.top * 0.5}px`};
		}

		/* right */
		&:nth-of-type(4n),
		&:nth-of-type(30) {
			left: 0;
			right: ${props => `${props.styles.left / 1.5}px`};
		}

		/* last row */
		&:nth-last-of-type(4),
		&:nth-last-of-type(3),
		&:nth-last-of-type(2),
		&:nth-last-of-type(1) {
			margin-bottom: 40px;
		}
	}
`;

const ExperimentInnerWrapper = styled.div`
	position: relative;

	@media (min-width: 1024px) {
		width: 20%;
		&.one-third {
			width: 75%;
			&:nth-of-type(2n) {
				width: 50%;
			}
		}
		&.one-quarter {
			width: 80%;
			&:nth-of-type(2n) {
				width: 66.6%;
			}
		}
		&.two-fifth {
			width: 95%;
			&:nth-of-type(2n) {
				width: 75%;
			}
		}
	}
`;

const VideoMask = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
`;

const ExperimentImage = styled.img`
	width: 100%;
	max-width: 100%;
	transform: scale(1) translate3d(0, 0, 0);
	transition: transform 1000ms cubic-bezier(0.23, 0.57, 0.4, 1), opacity 500ms;
`;

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		{
			allSanitySiteSettings {
				nodes {
					description
				}
			}

			allSanityExperimentPage {
				nodes {
					experimentsOrder {
						title
						coverLayout
						cover {
							asset {
								url
								mimeType
							}
						}
					}
				}
			}
		}
	`);

	const [experiments] = useState(
		data.allSanityExperimentPage.nodes[0].experimentsOrder.filter(item => item.cover !== null),
	);

	const generateRandom = (experiment, index) => {
		const isPhoto = experiment.cover.asset.mimeType.includes('image');

		const topRandom = (Math.random() * Math.floor(125)).toFixed(2);
		const leftRandom = (Math.random() * Math.floor(75)).toFixed(2);

		return (
			<ExperimentListItem
				key={index}
				data-id={index + 1}
				styles={{
					top: topRandom,
					left: leftRandom,
				}}>
				<ExperimentInnerWrapper className={experiment.coverLayout}>
					{isPhoto ? (
						<ExperimentImage src={`${experiment.cover.asset.url}?w=600`} />
					) : (
						<>
							<VideoMask></VideoMask>
							<Player
								playsInline
								autoPlay={true}
								loop={true}
								muted={true}
								src={experiment.cover.asset.url}></Player>
						</>
					)}
				</ExperimentInnerWrapper>
			</ExperimentListItem>
		);
	};

	return (
		<Layout experimentPage={true}>
			<SEO title='Experiments' description={data.allSanitySiteSettings.nodes[0].description} />

			<ExperimentsWrapper>
				{experiments.map((experiment, index) => {
					return generateRandom(experiment, index);
				})}
			</ExperimentsWrapper>
		</Layout>
	);
};

export default IndexPage;
